import React from "react"
import ReactDOM from "react-dom"

import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

// Enforce HTTPS and HSTS with a meta tag
const enforceHTTPSAndHSTS = () => {
  const meta = document.createElement("meta")
  meta.httpEquiv = "Content-Security-Policy"
  meta.content = "upgrade-insecure-requests"
  document.head.appendChild(meta)
}

enforceHTTPSAndHSTS()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
