import { FiatTokenToAmount, TokenToAmount } from "src/constants/types"

export enum EndpointKey {
  SEND_PHONE_OTP = "sendPhoneOTP",
  VERIFY_EMAIL_OTP_CREATE_USER = "verifyEmailOTPAndCreateUser",
  GET_USER = "getUser",
  VERIFY_PHONE_OTP = "verifyPhoneOTP",
  USER_DETAILS_SEND_EMAIL_OTP = "userDetailsAndEmailOTP",
  MERCHANT_TRANSACTION = "merchantTransaction",
  MERCHANT_TRANSACTIONS = "merchantTransactions",
  REFUND_INITIATE_WEBLINK = "refundInitiateWebLink",
  EXCHANGE_RATES = "exchangeRates",
  USER_TRANSACTION = "userTransaction",
  USER_TRANSACTIONS = "userTransactions",
  ISSUANCE_DETAILS = "issuanceDetails",
  P2P_TRANSACTIONS = "p2pTransaction",
  GENERATE_API_KEY = "generateAPIKey",
  INVITE_USER = "inviteUser",
  MERCHANT = "merchant",
  USER_LIST = "userList",
  ENABLE_USER = "enableUser",
  DISABLE_USER = "disableUser",
  GET_REFUND_FORM = "getRefundForm",
  REFUND_EXCHANGE_RATES = "refundExchangeRates",
  USER_VERIFY = "issuanceBankVerify",
  USER_VERIFY_RESET = "userVerifyReset",
  USER_VERIFY_RESET_ISSUANCE = "userVerifyResetIssuance",
  WALLET_USER_LIST = "walletUserList",
  ENABLE_DISABLE_WALLET_USER = "enableDisableWalletUser",
  WALLET_SUMMARY = "walletSummary",
  WALLET_BALANCE = "walletBalance",
  WALLET_REFUND = "walletRefund",
  WALLET_TRANSACTION_GRAPH = "walletTransactionGraph",
  WALLET_SUMMARY_GRAPH = "walletSummaryGraph",
  UPDATE_ISSUANCE_DETAILS = "updateIssuanceDetails",
  GET_WALLET_FEE_TYPE = "getWalletFeeType",
  GET_TRANSATION_TYPE = "getTransactionType",
  WALLET_FEE_CONFIG = "walletFeeConfig",
  ADD_WALLET_FEE_CONFIG = "addWalletFeeConfig",
  EDIT_WALLET_FEE_CONFIG = "addWalletFeeConfig",
  ADD_TRANSACTION_LIMIT_CONFIG = "addTransactionLimitConfig",
  EDIT_TRANSACTION_LIMIT_CONFIG = "editTransactionLimitConfig",
  GET_TRANSACTION_LIMIT_CONFIG = "getTransactionLimitConfig",
  FIAT_EXCHANGE_RATES = "fiatExchangeRates",
  CONVERSION_RATES = "conversionRates",
  ADD_CONVERSION_RATES = "addConversionRates",
  EDIT_CONVERSION_RATES = "editConversionRates",
  CONVERSION_RATES_ADJUSTMENT = "conversionRatesAdjustment",
  ADD_CONVERSION_RATES_ADJUSTMENT = "addConversionRatesAdjustment",
  EDIT_CONVERSION_RATES_ADJUSTMENT = "editConversionRatesAdjustment",
  GET_INDUSTRY_TYPE_LIST = "getIndustryTypeList",
  GET_FIAT_CURRENCY_LIST = "getFiatCurrencyList",
  SAVE_INSTITUTION_DETAILS = "saveInstitutionDetails",
  GET_INSTITUTION_DETAILS = "getInstitutionDetails",
  GET_COUNTRY_LIST = "getCountryList",
  GET_ALL_LANGUAGES = "getAllLanguages",
  ADD_LANGUAGE = "addLanguage",
  GET_MAPPED_LANGUAGES = "getMappedLanguages",
  MAPPED_LANGUAGE_INSTITUTION = "mappedLanguageInstitution",
  MAKE_LANGUAGE_DEFAULT = "makeLanguageDefault"
}

export const useApi: () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EndpointKey]: (...args: any[]) => string
} = () => {
  return {
    getUser: (query: string) => `user?${query}`,
    sendPhoneOTP: () => "user/registration/phone/",
    verifyPhoneOTP: () => "user/registration/phone/verify/",
    userDetailsAndEmailOTP: () => "user/registration/details/",
    verifyEmailOTPAndCreateUser: () => "user/registration/verify-and-create/",
    merchantTransaction: (id: string) => `merchant/transactions/${id}`,
    merchantTransactions: (query: string) =>
      `merchant/transaction/search?${query}`,
    refundInitiateWebLink: () => "merchantDashboard/initiateWebLinkRefund",
    exchangeRates: (fiat: string) => `v1/config/exchangeRates?from=${fiat}`,
    userTransaction: (id: string) => `user/transactions/${id}`,
    userTransactions: (query: string) =>
      `merchantDashboard/transactions?${query}`,
    merchant: () => "merchantDashboard/admin/merchant/",
    p2pTransaction: () => "merchantDashboard/admin/p2p_transaction",
    generateAPIKey: () => "merchantDashboard/admin/api-key",
    inviteUser: () => "merchantDashboard/admin/invite/",
    issuanceDetails: () => "issuance/dashboard/issuanceDetails",
    userList: () => "merchantDashboard/getAllUsersOfMerchant",
    enableUser: () => "merchantDashboard/admin/enable",
    disableUser: () => "merchantDashboard/admin/disable",
    getRefundForm: () => "api/v1/merchantDashboard/getRefundDetailsFromToken",
    refundExchangeRates: (query: string) =>
      `api/v1/merchantDashboard/exchangeRates?from=${query}`,
    issuanceBankVerify: (query: string) => `api/v1/issuanceBankVerify?${query}`,
    userVerifyReset: (query: string) => `api/v1/userVerifyReset?${query}`,
    userVerifyResetIssuance: (query: string) =>
      `api/v1/userVerifyResetIssuanceDash?${query}`,
    walletUserList: () => `issuance/user/getWalletUser`,
    enableDisableWalletUser: () => `issuance/user/enableDisableWalletUser`,
    walletSummary: () => "issuanceWallet/admin/walletSummary",
    walletBalance: () => "issuanceWallet/admin/walletBalance",
    walletRefund: () => "issuanceWallet/admin/walletRefund",
    walletTransactionGraph: () => "issuanceWallet/admin/transactionGraph",
    walletSummaryGraph: () => "issuanceWallet/admin/walletSummaryGraph",
    updateIssuanceDetails: () => "issuance/dashboard/updateIssuanceDetails",
    getWalletFeeType: () => "issuance/admin/getWalletFeeType",
    getTransactionType: () => "issuance/admin/getTransactionType",
    addWalletFeeConfig: () => "issuance/admin/saveWalletFeeConfig",
    editWalletFeeConfig: () => "issuance/admin/saveWalletFeeConfig",
    walletFeeConfig: () => "issuance/admin/getWalletFeeConfig",
    addTransactionLimitConfig: () =>
      "issuance/admin/saveTransactionLimitConfig",
    editTransactionLimitConfig: () =>
      "issuance/admin/saveTransactionLimitConfig",
    getTransactionLimitConfig: () => "issuance/admin/getTransactionLimitConfig",
    fiatExchangeRates: (query: string) =>
      `issuanceWallet/admin/fiatExchangeRate?${query}`,
    addConversionRates: () => "issuance/admin/addConversionRates",
    editConversionRates: () => "issuance/admin/addConversionRates",
    conversionRates: () => "issuance/admin/conversionRates",
    conversionRatesAdjustment: () =>
      "issuance/admin/getConversionRatesAdjustment",
    addConversionRatesAdjustment: () =>
      "issuance/admin/addConversionRatesAdjustment",
    editConversionRatesAdjustment: () =>
      "issuance/admin/addConversionRatesAdjustment",
    getIndustryTypeList: () => "issuance/getIndustryTypeList",
    getFiatCurrencyList: () => "issuance/getFiatCurrencyList",
    saveInstitutionDetails: () => "issuance/admin/saveInstitutionDetails",
    getInstitutionDetails: () => "issuance/getInstitutionDetails",
    getCountryList: () => "language/getCountyList",
    getAllLanguages: () => "language/getAllLanguages",
    addLanguage: () => "language/addLanguage",
    getMappedLanguages: () => "language/getMappedLanguages",
    mappedLanguageInstitution: () => "language/mappedLanguageInstitution",
    makeLanguageDefault: () => "language/makeLanguageDefault"
  } as const
}

export const calculateTimeLeft: (targetDate: Date) => number = (targetDate) => {
  const now = new Date()
  const difference = +targetDate - +now
  return difference > 0 ? Math.floor((difference / 1000) % 60) : 0
}

export const AssetFractionDigits: TokenToAmount = {
  BTC: 8,
  ETH: 8,
  USDT: 8,
  WTK: 8,
  USDC: 8
}

export const FiatAssetFractionDigits: FiatTokenToAmount = {
  USD: 2,
  EUR: 2,
  INR: 1,
  IDR: 1,
  SGD: 2,
  GBP: 2,
  PKR: 1,
  PHP: 1,
  AED: 2,
  WTK: 1,
  USDC: 1
}

export const Frequency = [
  {
    label: "Per Transaction",
    value: "PER_TRANSACTION"
  },
  {
    label: "One Time",
    value: "ONE_TIME"
  },
  {
    label: "Daily",
    value: "DAILY"
  },
  {
    label: "Weekly",
    value: "WEEKLY"
  },
  {
    label: "Monthly",
    value: "MONTHLY"
  },
  {
    label: "Quarterly",
    value: "QUARTERLY"
  },
  {
    label: "Half Yearly",
    value: "HALF_YEARLY"
  },
  {
    label: "Yearly",
    value: "YEARLY"
  }
]

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua & Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Caribbean Netherlands", code: "BQ" },
  { name: "Bosnia & Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo - Brazzaville", code: "CG" },
  { name: "Congo - Kinshasa", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Côte d’Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Islas Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard & McDonald Islands", code: "HM" },
  { name: "Vatican City", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "North Korea", code: "KP" },
  { name: "South Korea", code: "KR" },
  { name: "Kosovo", code: "XK" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "North Macedonia", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar (Burma)", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Curaçao", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn Islands", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Réunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "St. Barthélemy", code: "BL" },
  { name: "St. Helena", code: "SH" },
  { name: "St. Kitts & Nevis", code: "KN" },
  { name: "St. Lucia", code: "LC" },
  { name: "St. Martin", code: "MF" },
  { name: "St. Pierre & Miquelon", code: "PM" },
  { name: "St. Vincent & Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "São Tomé & Príncipe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Serbia", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia & South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard & Jan Mayen", code: "SJ" },
  { name: "Eswatini", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syria", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad & Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks & Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "U.S. Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "British Virgin Islands", code: "VG" },
  { name: "U.S. Virgin Islands", code: "VI" },
  { name: "Wallis & Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" }
]
